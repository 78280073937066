import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Box, Button } from '@mui/material';
import {
  P,
  ColourScoreBar,
  Switch,
} from '@insights-ltd/design-library/components';
import { ProfileResponse, emailProfile } from 'api';
import DistributionMethodDialog from 'components/DistributionMethodDialog';
import PronounsDialectLabel from 'components/PronounsDialectLabel';
import { DIALECT_CODES, PROFILE_TYPES } from 'variables';
import { AllDialectOptions } from 'types/dialects';
import DownloadProgressDialog from 'components/DownloadProgressDialog';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import EmailProgressDialog from 'components/EmailProgressDialog';
import { isoToLocaleString } from 'utils/dates';
import { isApiProfile, isExploreProfile } from 'domain/profile';
import Grid from '@mui/material/Grid';
import { AnonymisationType } from 'types/types';
import downloadProfile from 'api/downloadProfile';

type Props = {
  profile: ProfileResponse;
  learnerId: string;
  disabled?: boolean;
  selectedDialect: AllDialectOptions;
  dialectString?: string;
  onProfileDownloadError: () => void;
};

const ProfileCard = ({
  profile,
  learnerId,
  disabled = false,
  selectedDialect,
  dialectString,
  onProfileDownloadError,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { language: locale } = i18n;
  const [showDownloadProgress, setShowDownloadProgress] = useState(false);
  const [showEmailProgress, setShowEmailProgress] = useState(false);
  const [showDistOptions, setShowDistOptions] = useState(false);
  const isDFCProfile = profile.type === 'DFC_PROFILE';
  const anonymisationValue = isDFCProfile ? 'NAMED' : undefined;
  const disabledDFCProfileTile = isDFCProfile && profile.contributorCount === 0;
  const hasContributors = (profile?.contributorCount ?? 0) > 0;
  const [anonymisationType, setAnonymisationValue] = useState<
    AnonymisationType | undefined
  >(anonymisationValue);

  const dialect = Object.values(DIALECT_CODES).includes(selectedDialect)
    ? selectedDialect
    : 'en-GB';
  const downloadDialect =
    selectedDialect === DIALECT_CODES.EVALUATION_DIALECT
      ? profile.dialect
      : dialect;

  const handleAnonymisationSwitchChange = (value: boolean) => {
    setAnonymisationValue(value ? 'ANONYMISED' : 'NAMED');
  };

  const onDownloadProfile = async () => {
    try {
      setShowDownloadProgress(true);
      await downloadProfile(
        learnerId,
        profile.id,
        downloadDialect,
        anonymisationType,
      );
    } catch (e) {
      onProfileDownloadError();
    } finally {
      setShowDownloadProgress(false);
    }
  };

  const handleSendEmail = async (format?: string) => {
    await emailProfile(
      learnerId,
      profile.id,
      downloadDialect,
      format,
      anonymisationType,
    );

    setShowDistOptions(false);
    setShowEmailProgress(true);
  };

  const onEmailProfile = () => {
    setShowDistOptions(true);
  };

  const onExploreEmailProfile = async (format?: string) => {
    try {
      await emailProfile(learnerId, profile.id, downloadDialect, format);
      setShowEmailProgress(true);
    } catch (e) {
      onProfileDownloadError();
    }
  };

  const isProfileShareable: boolean =
    !isExploreProfile(profile) &&
    !isApiProfile(profile) &&
    !disabledDFCProfileTile;

  return (
    <>
      <Card
        elevation={0}
        sx={(theme) => ({
          display: 'flex',
          height: '100%',
          border: `1px solid ${theme.palette.grey[500]}`,
          backgroundColor:
            disabled || disabledDFCProfileTile
              ? theme.palette.grey[200]
              : 'undefined',
          borderColor:
            disabled || disabledDFCProfileTile ? 'transparent' : 'undefined',
          '& * p': {
            color:
              disabled || disabledDFCProfileTile
                ? theme.palette.grey[700]
                : 'undefined',
          },
        })}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            justifyContent: 'space-between',
            '& > div:last-of-type': {
              marginTop: 'auto',
            },
          }}
        >
          <Box mb={(theme) => theme.spacing(spacingSizeMap.L)}>
            <P variant="body-large" color="primary">
              {`${t(PROFILE_TYPES[profile.type])} - `}
              {isoToLocaleString(profile.evaluationSubmittedOn, locale)}
            </P>

            <PronounsDialectLabel
              pronoun={profile.pronoun}
              dialect={profile.dialect}
            />
            {disabledDFCProfileTile && (
              <Grid
                sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 1 }}
              >
                <P variant="body-bold">
                  {t('ui.event-management.learner.profiles.zero-contributors')}
                </P>
                <P>
                  {t(
                    'ui.event-management.learner.profiles.profile-not-available',
                  )}
                </P>
              </Grid>
            )}
          </Box>
          <Box mt={(theme) => theme.spacing(spacingSizeMap.L)}>
            {!isDFCProfile && (
              <Box sx={{ marginBottom: !isApiProfile(profile) ? '0' : '20px' }}>
                <ColourScoreBar
                  scores={profile.colourScores.conscious}
                  hideZeroScoreBar={false}
                  experienceType={profile.type}
                  labels={{
                    red: 'R',
                    green: 'G',
                    blue: 'B',
                    yellow: 'Y',
                  }}
                />
              </Box>
            )}
            {hasContributors && isDFCProfile && (
              <Grid sx={{ ml: '0.5rem' }}>
                <Switch
                  checked={anonymisationType === 'ANONYMISED'}
                  onChange={handleAnonymisationSwitchChange}
                  labelLeft={t(
                    'ui-event-management.events.select-contributor-option-named',
                  )}
                  labelRight={t(
                    'ui-event-management.events.select-contributor-option-anonymised',
                  )}
                  disabled={disabled}
                  data-testid="anonymisation-switch"
                />
              </Grid>
            )}
            {isExploreProfile(profile) && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() =>
                  onExploreEmailProfile('share-digital-profile-link')
                }
                disabled={!!disabled}
                sx={(theme) => ({
                  borderColor: disabled
                    ? `${theme.palette.grey[600]} !important`
                    : 'undefined',
                  marginTop: theme.spacing(spacingSizeMap.S),
                })}
              >
                {t('ui.event-management.learner.profiles.share-profile-link')}
              </Button>
            )}
            {isProfileShareable && (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onEmailProfile}
                  disabled={!!disabled}
                  sx={(theme) => ({
                    borderColor: disabled
                      ? `${theme.palette.grey[600]} !important`
                      : 'undefined',
                    marginTop: theme.spacing(spacingSizeMap.L),
                  })}
                >
                  {t('ui.event-management.learner.profiles.share')}
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={onDownloadProfile}
                  disabled={!!disabled}
                  sx={(theme) => ({
                    borderColor: disabled
                      ? `${theme.palette.grey[600]} !important`
                      : 'undefined',
                    marginTop: theme.spacing(spacingSizeMap.XS),
                  })}
                >
                  {t('ui.event-management.learner.profiles.download')}
                </Button>
              </>
            )}
          </Box>
        </CardContent>
      </Card>
      <DownloadProgressDialog
        open={showDownloadProgress}
        dialect={dialectString}
      />
      <EmailProgressDialog
        singleProfile
        open={showEmailProgress}
        dialect={dialectString}
        onClose={() => setShowEmailProgress(false)}
      />
      <DistributionMethodDialog
        open={showDistOptions}
        profileCount={1}
        onSendViaLink={() => handleSendEmail('link')}
        onSendViaEmail={() => handleSendEmail('attachment')}
        onCancel={() => setShowDistOptions(false)}
      />
    </>
  );
};

export default ProfileCard;
