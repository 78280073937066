import { Box, Container } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import FullHeight from 'components/FullHeight';
import { FullScreenError, FullScreenSpinner } from 'components/FullScreen';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { spacingSizeMap } from '@insights-ltd/design-library/themes';
import { Score } from 'api';
import TeamWheel from './TeamWheel';
import WheelProvider, { defaultInitialState } from './WheelProvider';
import { WheelState } from './hooks/useWheelContext';

import { TeamWheelType, useGetTeamWheelData } from './useGetTeamWheelData';

type DownloadTeamWheelProps = {
  type: TeamWheelType;
  test?: boolean;
};

const DownloadTeamWheel = ({ type, test = false }: DownloadTeamWheelProps) => {
  const { eventId, teamId } = useParams<{ eventId: string; teamId: string }>();
  const { t } = useTranslation();

  const {
    responseStatus,
    wheelPositions,
    teamScores,
    team,
    eventScores,
    event,
    isLearnerCountExceeded,
    activeOrganisations,
    teamOrganisations,
    purchasedCount,
  } = useGetTeamWheelData({ eventId, type, teamId });

  if (responseStatus === 'pending') {
    return <FullScreenSpinner message={t('ui.event-management.loading')} />;
  }
  if (responseStatus === 'error') {
    return (
      <FullScreenError
        message={t(
          'ui.event-management.events.download-profiles.error-loading-learners',
        )}
      />
    );
  }

  const crumbs =
    type === 'Experience'
      ? {
          '/': t('ui.event-management.events.nav.home'),
          '/experiences': t('ui.event-management.events.title'),
          [`/experiences/${eventId}`]: t(event!.name),
        }
      : {
          '/': t('ui.event-management.events.nav.home'),
          '/learners': t('ui.event-management.learners-and-teams.title'),
          [`/teams/${teamId}`]: t(team!.name),
        };

  const scores:
    | (Score & {
        organisationId?: string;
      })[]
    | undefined = type === 'Experience' ? eventScores : teamScores;
  const { name } = type === 'Experience' ? event! : team!;
  const initialState: WheelState = {
    ...defaultInitialState,
    activeOrganisations,
    teamOrganisations,
    isMultiOrg: team?.organisationContext?.type === 'MULTIPLE',
  };

  const wheelState: WheelState = isLearnerCountExceeded
    ? {
        ...initialState,
        scoresGroupingType: 'AGGREGATED',
        labelType: 'NUMBER',
        isLearnerCountExceeded,
      }
    : initialState;

  return (
    <>
      <Helmet>
        <title>{t('ui.event-management.title.team-wheel')}</title>
      </Helmet>

      <FullHeight backgroundColor="white">
        <Container
          maxWidth="lg"
          sx={(theme) => ({ marginBottom: theme.spacing(8) })}
        >
          <Box sx={(theme) => ({ py: theme.spacing(spacingSizeMap.M) })}>
            <Breadcrumbs
              crumbs={crumbs}
              activeText={t('ui.event-management.events.team-wheel.heading')}
            />
          </Box>
          <WheelProvider initialState={wheelState}>
            <TeamWheel
              teamId={teamId}
              eventId={eventId}
              type={type}
              test={test}
              rawScores={scores!}
              name={name}
              purchasedCount={purchasedCount}
              teamLearnerCount={
                type === 'Experience' ? purchasedCount : team?.learnerCount ?? 0
              }
              rawWheelPositions={wheelPositions}
            />
          </WheelProvider>
        </Container>
      </FullHeight>
    </>
  );
};

export default DownloadTeamWheel;
