import { ColourScore } from 'api';
import React, { Fragment } from 'react';
import { getTheme } from '@insights-ltd/design-library/themes';
import { Colour } from '@insights-ltd/design-library/components';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const localTheme = getTheme();

const colourMap: Record<Colour, string> = {
  red: localTheme.fourColourProfileColours.fieryRed,
  green: localTheme.fourColourProfileColours.earthGreen,
  blue: localTheme.fourColourProfileColours.coolBlue,
  yellow: localTheme.fourColourProfileColours.sunshineYellow,
};

const SCORE_THRESHOLD = 300;
const CIRCLE_WIDTH = 9;

type ColourOrderPreferenceProps = {
  colours: ColourScore;
  test?: boolean;
};

const ColourOrderPreference = ({
  colours,
  test = false,
}: ColourOrderPreferenceProps) => {
  const theme = useTheme();
  const textColourMap: Record<Colour, string> = {
    red: theme.palette.common.white,
    green: theme.palette.common.white,
    blue: theme.palette.common.white,
    yellow: theme.palette.grey[700],
  };
  const sorted = Object.entries(colours).sort(([, a], [, b]) => b - a);
  const dividerIndex = sorted.findIndex(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, score]) => score < SCORE_THRESHOLD,
  );

  const { t } = useTranslation();

  const colourText: Record<Colour, string> = {
    red: t('ui.event-management.events.team-wheel.table.order-preference.red'),
    green: t(
      'ui.event-management.events.team-wheel.table.order-preference.green',
    ),
    blue: t(
      'ui.event-management.events.team-wheel.table.order-preference.blue',
    ),
    yellow: t(
      'ui.event-management.events.team-wheel.table.order-preference.yellow',
    ),
  };

  return (
    <g>
      {sorted.map(([colour, score], i) => {
        // circles after the divider are pushed out an extra CIRCLE_WIDTH
        const x =
          score < SCORE_THRESHOLD ? (i + 1) * CIRCLE_WIDTH : i * CIRCLE_WIDTH;
        return (
          <Fragment key={colour}>
            {dividerIndex === i && (
              <g transform={`matrix(1 0 0 1 ${x + CIRCLE_WIDTH * 0.1} 0)`}>
                <g fill="none">
                  <line
                    stroke={theme.palette.grey[600]}
                    strokeWidth="0.33"
                    x1="-7"
                    y1="6.5"
                    x2="-5"
                    y2="1.5"
                  />
                </g>
              </g>
            )}
            <g fill="none">
              <g
                transform={`matrix(1 0 0 1 ${
                  x - (dividerIndex > i ? -2.5 : 2.5)
                } 0)`}
                data-testid={test ? `${colour}-circle` : null}
              >
                <circle
                  cx="4"
                  cy="4"
                  r="3.5"
                  fill={colourMap[colour as Colour]}
                  stroke={theme.palette.grey[800]}
                  strokeWidth="0.25"
                />
                <text
                  x="4"
                  y="4.5"
                  fontSize="5px"
                  fontWeight="600"
                  fill={textColourMap[colour as Colour]}
                  textAnchor="middle"
                  alignmentBaseline="middle"
                >
                  {colourText[colour as Colour]}
                </text>
              </g>
            </g>
          </Fragment>
        );
      })}
    </g>
  );
};
export default ColourOrderPreference;
