import { styled } from '@mui/material';
import { ColourScore, Score } from 'api';
import { getTheme } from '@insights-ltd/design-library/themes';
import {
  getConsciousScores,
  getDominantPieChart,
  getLessConsciousScores,
  getPreferredPieChart,
} from 'pages/DownloadTeamWheel/scoreUtils';
import { colourMap } from 'pages/DownloadTeamWheel/TeamWheel72/teamWheelUtils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWheelContext } from 'pages/DownloadTeamWheel/hooks/useWheelContext';

const localTheme = getTheme();

export type Point = {
  x: number;
  y: number;
};

type StringPoint = {
  x: string;
  y: string;
};

type ChartType = 'PREFERRED' | 'DOMINANT';

type TeamEnergyChartProps = {
  scores: Score[];
  chartTitleKey: string;
  svgId: string;
  chartType: ChartType;
};

const roundToThousandths = (input: number) =>
  (Math.round(input * 10000) / 10000).toFixed(4);

const formatPercent = (input: number) =>
  `${parseFloat((input * 100).toFixed(2))} %`;

const circleCircumferenceCoordinates = (
  percent: number,
  radius: number,
  origin: Point,
): StringPoint => {
  const correctedPercent = percent - 0.25; // radians are measured clockwise from the x-axis
  const x = Math.cos(2 * Math.PI * correctedPercent) * radius;
  const y = Math.sin(2 * Math.PI * correctedPercent) * radius;
  return {
    x: roundToThousandths(x + origin.x),
    y: roundToThousandths(y + origin.y),
  };
};

export const pieSegmentPathData = (
  startPercent: number,
  percent: number,
  radius: number,
  origin: Point,
) => {
  const start = circleCircumferenceCoordinates(startPercent, radius, origin);
  const cappedPercent = percent === 1 ? 0.9999999 : percent;
  const end = circleCircumferenceCoordinates(
    startPercent + cappedPercent,
    radius,
    origin,
  );
  return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${
    percent > 0.5 ? 1 : 0
  } 1 ${end.x} ${end.y} L ${origin.x} ${origin.y} Z`;
};

const StyledSvg = styled('svg')(({ theme }) => ({
  display: 'inline-block',
  width: '50%',
  height: '100%',
  margin: 'auto',
  fontFamily: theme.typography.fontFamily,
}));

const TeamEnergyChart = ({
  scores,
  chartTitleKey,
  svgId,
  chartType = 'PREFERRED',
}: TeamEnergyChartProps) => {
  const { t } = useTranslation();
  const [{ scoreType }] = useWheelContext();
  const origin: Point = { x: 195, y: 248 };
  const radius = 140;
  const groupScores =
    scoreType === 'CONSCIOUS'
      ? getConsciousScores(scores)
      : getLessConsciousScores(scores);
  const chartScores =
    chartType === 'PREFERRED'
      ? getPreferredPieChart(groupScores)
      : getDominantPieChart(groupScores);
  const startScores: ColourScore = {
    red: 0,
    yellow: chartScores.red,
    green: chartScores.red + chartScores.yellow,
    blue: chartScores.red + chartScores.yellow + chartScores.green,
  };
  let baseColour = localTheme.grey[100];
  Object.entries(chartScores).forEach(([colourName, score]) => {
    if (score === 1) {
      baseColour = colourMap[colourName as keyof ColourScore];
    }
  });
  const legendSize = 35;
  const legendMargin = 15;
  const legendX = origin.x + radius + legendSize;
  const textOffset = (legendSize + legendMargin / 2) / 2;

  const TITLE_LENGTH_THRESHOLD = 21;
  const title = t(chartTitleKey);
  return (
    <StyledSvg id={svgId} viewBox="0 0 480 390">
      <g id="chart">
        <circle
          r={radius}
          cx={origin.x}
          cy={origin.y}
          fill={baseColour}
          stroke={localTheme.grey[800]}
        />
        <path
          d={pieSegmentPathData(
            startScores.red,
            chartScores.red,
            radius,
            origin,
          )}
          fill={localTheme.fourColourProfileColours.fieryRed}
          stroke={localTheme.grey[800]}
        />
        <path
          d={pieSegmentPathData(
            startScores.yellow,
            chartScores.yellow,
            radius,
            origin,
          )}
          fill={localTheme.fourColourProfileColours.sunshineYellow}
          stroke={localTheme.grey[800]}
        />
        <path
          d={pieSegmentPathData(
            startScores.green,
            chartScores.green,
            radius,
            origin,
          )}
          fill={localTheme.fourColourProfileColours.earthGreen}
          stroke={localTheme.grey[800]}
        />
        <path
          d={pieSegmentPathData(
            startScores.blue,
            chartScores.blue,
            radius,
            origin,
          )}
          fill={localTheme.fourColourProfileColours.coolBlue}
          stroke={localTheme.grey[800]}
        />
      </g>
      <g id="legend">
        <rect
          x={legendX}
          y={origin.y - (legendSize + legendMargin) * 2}
          width={legendSize}
          height={legendSize}
          fill={localTheme.fourColourProfileColours.fieryRed}
          stroke={localTheme.grey[800]}
        />
        <text
          x={legendX + legendSize + legendMargin}
          y={origin.y + textOffset - (legendSize + legendMargin) * 2}
          fontSize="14"
        >
          {formatPercent(chartScores.red)}
        </text>
        <rect
          x={legendX}
          y={origin.y - (legendSize + legendMargin)}
          width={legendSize}
          height={legendSize}
          fill={localTheme.fourColourProfileColours.sunshineYellow}
          stroke={localTheme.grey[800]}
        />
        <text
          x={legendX + legendSize + legendMargin}
          y={origin.y - (legendSize + legendMargin) + textOffset}
          fontSize="14"
        >
          {formatPercent(chartScores.yellow)}
        </text>
        <rect
          x={legendX}
          y={origin.y}
          width={legendSize}
          height={legendSize}
          fill={localTheme.fourColourProfileColours.earthGreen}
          stroke={localTheme.grey[800]}
        />
        <text
          x={legendX + legendSize + legendMargin}
          y={origin.y + textOffset}
          fontSize="14"
        >
          {formatPercent(chartScores.green)}
        </text>
        <rect
          x={legendX}
          y={origin.y + (legendSize + legendMargin)}
          width={legendSize}
          height={legendSize}
          fill={localTheme.fourColourProfileColours.coolBlue}
          stroke={localTheme.grey[800]}
        />
        <text
          x={legendX + legendSize + legendMargin}
          y={origin.y + (legendSize + legendMargin) + textOffset}
          fontSize="14"
        >
          {formatPercent(chartScores.blue)}
        </text>
      </g>
      <g id="title">
        <text
          x={origin.x}
          y={origin.y - (radius + legendMargin)}
          fontSize={title.length > TITLE_LENGTH_THRESHOLD ? '26' : '32'}
          textAnchor="middle"
        >
          {title}
        </text>
      </g>
    </StyledSvg>
  );
};

export default TeamEnergyChart;
