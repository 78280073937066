import { Colour } from '@insights-ltd/design-library/components';
import { Dialects, PreferredDialect, Pronoun } from 'types/dialects';
import {
  Chapters as Chapter,
  Contributor,
  Evaluator,
  EventType,
  Frequency,
  InviteeColorScores,
  InviteeListOptions,
  JobTitle,
  JungianColours,
  LicensedProduct,
  ProfileType,
  Role,
} from 'types/types';

export interface RequestErrorBody {
  errors: { code: string }[];
  message: string;
  status: number;
  ts: string;
}

export class RequestError extends Error {
  readonly errorCodes: string[];

  readonly status: number;

  getRequestErrorKeys = (
    defaultKey: string,
    httpCodeKeys: Record<number, string> = {},
    errorCodeKeys: Record<string, string> = {},
  ) => {
    const genericError = httpCodeKeys[this.status]
      ? [httpCodeKeys[this.status]]
      : null;
    const specificErrorStrings = this.errorCodes.reduce(
      (accumulator: string[], currCode: string) => {
        if (errorCodeKeys[currCode]) {
          accumulator.push(errorCodeKeys[currCode]);
        }
        return accumulator;
      },
      [],
    );
    if (specificErrorStrings && specificErrorStrings.length) {
      return specificErrorStrings;
    }
    if (genericError) {
      return genericError;
    }
    return [defaultKey];
  };

  constructor(message: string, responseBody: RequestErrorBody) {
    // 'Error' breaks prototype chain here in ES5
    super(message);
    // restore prototype chain
    Object.setPrototypeOf(this, RequestError.prototype);

    this.status = responseBody.status;
    this.errorCodes = responseBody.errors.map((codeObj) => codeObj.code);
  }
}

export interface RemoteLocation {
  type: 'REMOTE';
  meetingLink: string;
  details: string;
}

export interface PhysicalLocation {
  type: 'PHYSICAL';
  addressLine1: string;
  addressLine2: string;
  city: string;
  postcode: string;
  state: string;
  country: string;
}

export type EventLocation = RemoteLocation | PhysicalLocation;

export interface IdObject {
  id: string;
}

export interface EventRequest {
  name: string;
  eventType: string;
  productType: LicensedProduct;
  dialect: string;
  timezone: string;
  startsAt: string;
  endsAt: string;
  deadlineAt?: string;
  notificationFrequency: string;
  location: EventLocation;
  practitioners: IdObject[];
  organisation?: OrganisationResponse;
  customEmailMessage?: string;
}

export interface PurchaseLearnerChapterRequest {
  product: string;
  createProfile: {
    evaluations: string[];
    chapters: string[];
  };
  updateProfile: {
    profile: string;
    chapters: string[];
  };
}

export interface ProfilePurchaseLine {
  profile: string;
  chapters: string[];
}

export interface LearnerPurchaseLine {
  learner: string;
  evaluations: string[];
  chapters: string[];
}

export interface LearnerPurchaseRequest {
  product: string;
  createProfiles: LearnerPurchaseLine[];
  updateProfiles: ProfilePurchaseLine[];
}

export interface ChapterCost {
  name: string;
  cost: number;
}

export interface EventResponse {
  id: string;
  name: string;
  eventType: EventType;
  eventStatus: 'ACTIVE' | 'CANCELLED';
  productType: LicensedProduct;
  dialect: string;
  timezone: string;
  startsAt: string;
  endsAt: string;
  deadlineAt: string;
  notificationFrequency: Frequency | '';
  location: EventLocation;
  practitioners: PractitionerData[];
  organisation?: OrganisationResponse;
  cancelledAt?: string;
  customEmailMessage?: string;
  wallet?: WalletResponse;
  budget?: EventBudget;
}

export interface EventBudget {
  total: number;
  potentialSpend: number;
  spent: number;
}

interface Chapters {
  chapters: ChapterCost[];
}

export interface EventResponseWithChapters extends EventResponse, Chapters {}

export interface EventSummaryResponse {
  id: string;
  name: string;
  eventType: EventType;
  startsAt: string;
  endsAt: string;
  timezone: string;
  organisation?: OrganisationResponse;
  cancelledAt?: string | null;
}

export type ProfileStatus =
  | 'NONE'
  | 'REUSED'
  | 'PURCHASED'
  | 'AVAILABLE_FOR_REUSE';

export type ProfileReuseStatus = 'NONE' | 'REQUEST' | 'DECLINE';

export type ChapterStatus = 'PURCHASED' | 'PENDING';

export interface ChaptersStatus {
  name: string;
  status?: ChapterStatus;
}

export interface EvaluationStatus {
  submittedOn: string;
}

export interface InviteeResponse {
  id: string;
  email: string;
  fullName: string;
  status: InviteeListOptions;
  chapters: ChaptersStatus[];
  dialect: Dialects;
  profileStatus: ProfileStatus;
  profileReusePreference: ProfileReuseStatus;
  profileCreatedAt?: string;
  lastInviteAt?: string;
  profileLastSentAt?: string;
  leadingColourEnergy?: JungianColours;
  evaluation?: EvaluationStatus;
  pronoun?: Pronoun;
  scores?: InviteeColorScores;
  profile: {
    id: string;
  };
}

export interface DfcInviteeResponse extends Omit<InviteeResponse, 'dialect'> {}

export interface InviteeRequest {
  email: string;
  fullName: string;
}

export interface UserInvite {
  emailAddress: string;
  sentAt: string;
  expiresAt: string;
}

export interface SignUpRequest {
  fullName: string;
  password: string;
  preferredDialect: string;
  token: string;
}

export interface SignInRequest {
  emailAddress: string;
  password: string;
}

export interface ForgottenPasswordData {
  emailAddress: string;
}

export interface PasswordUpdateRequest {
  oldPassword: string;
  newPassword: string;
  dialect?: string;
}

export interface PractitionerData {
  fullName: string;
  emailAddress: string;
  id: string;
  leadingColourEnergy: JungianColours | null;
  licensedProducts: LicensedProduct[];
  jobTitle: JobTitle;
  roles: Role[];
  enabled: boolean;
  preferredDialect?: PreferredDialect | Dialects;
}

export interface PractitionerSummaryData {
  fullName: string;
  emailAddress: string;
  id: string;
  leadingColourEnergy?: JungianColours;
  jobTitle: JobTitle;
  createdAt: string;
  totalEvents: number;
  roles: Role[];
  enabled: boolean;
}

export const COLOUR_SCORE_COLOURS = ['red', 'blue', 'green', 'yellow'] as const;

export type ColourScoreColour = (typeof COLOUR_SCORE_COLOURS)[number];

export type ColourScore = Record<Colour, number>;

export interface ColourScoreWithPreference extends ColourScore {
  preferred: Colour[];
}

export interface Score {
  id: string;
  fullName: string;
  wheelPositions: {
    conscious: number;
    lessConscious: number;
    opposite: number;
  };
  colourScores: {
    conscious: ColourScoreWithPreference;
    lessConscious: ColourScoreWithPreference;
    opposite: ColourScoreWithPreference;
  };
  sortScoreArrays?: number[][];
}

export interface OrganisationResponse {
  id: string;
  name: string;
  learnerCount?: number;
  dataRetentionPolicy: {
    maximumInactivityPeriod: string;
  };
}

export interface SupportedRolesResponse {
  supportedRoles: Role[];
}

export interface OrganisationSupportedRole {
  organisationId: string;
  supportedRoles: Role[];
}

export interface PractitionerRequest {
  fullName: string;
  emailAddress: string;
  leadingColourEnergy?: JungianColours;
  preferredDialect?: PreferredDialect | Dialects;
  licensedProducts: LicensedProduct[];
  jobTitle?: string;
  roles: Role[];
}

export interface PractitionerProduct {
  name: LicensedProduct;
  chapters: ChapterCost[];
}
export interface PractitionerProductsResponse {
  products: PractitionerProduct[];
}

export interface LearnerRequest {
  fullName: string;
  knownAs: string;
  emailAddress: string;
}

export interface UpdateLearnerResponse {
  fullName: string;
  knownAs: string;
  emailAddress: string;
}

export interface LearnerResponse {
  id: string;
  fullName: string;
  knownAs: string | null;
  primaryEmail: string;
}

export interface Profile {
  id: string;
  createdAt: string;
  chapters: string[];
  profileType?: string;
}

export interface LearnerEvaluation {
  id: string;
  submittedOn: string;
  latestProfile: Profile | null;
  latestProfiles: Profile[] | [];
}

export interface LearnerAndInviteeResponse extends LearnerResponse {
  preferredDialect: Dialects;
  latestProfile?: Omit<Profile, 'chapters'> & {
    profileType?: ProfileType;
    evaluation: LearnerAndInviteeResponse['latestEvaluation'];
  };
  colourScores?: {
    conscious: Record<ColourScoreColour, number> & {
      preferred: ColourScoreColour[];
    };
  } | null;
  latestEvaluation?: {
    id: string;
    submittedOn: string;
  };
  leadingColourEnergy?: JungianColours;
  organisation: OrganisationResponse;
}

export interface LearnerWithEvaluation extends LearnerResponse {
  latestEvaluation: LearnerEvaluation;
  leadingColourEnergy?: JungianColours;
}

export interface FilteredLearnerResponse {
  learners: LearnerAndInviteeResponse[];
  limitExceeded: boolean;
}

export interface ProfileResponse {
  id: string;
  colourScores: {
    conscious: ColourScore;
    lessConscious: ColourScore;
    opposite: ColourScore;
  };
  createdOn: string;
  pronoun?: Pronoun;
  dialect: Dialects;
  evaluationSubmittedOn: string;
  type: ProfileType;
  contributorCount?: number;
  supportedDialects: Dialects[];
}

export interface WalletResponse {
  id: string;
  availableUnits: number;
}

interface TransactionResponseCommon {
  walletId: string;
  units: number;
  walletBalance: number;
  reference: string;
  comment?: string;
  createdBy: {
    id: string;
    fullName: string;
  };
  createdAt: string;
  recipient: {
    fullName: string;
  };
  organisation?: {
    id: string;
    name: string;
  };
}

interface CommerceTransactionResponseCommon extends TransactionResponseCommon {
  sender: { fullName: string };
}

interface CreditTransactionResponse extends TransactionResponseCommon {
  operation: 'CREDIT';
}

interface RemoveTransactionResponse extends TransactionResponseCommon {
  operation: 'REMOVE';
}

interface PurchaseTransactionResponse
  extends CommerceTransactionResponseCommon {
  operation: 'PURCHASE';
}

interface TransferTransactionResponse
  extends CommerceTransactionResponseCommon {
  operation: 'TRANSFER';
}

export type TransactionResponse =
  | CreditTransactionResponse
  | RemoveTransactionResponse
  | TransferTransactionResponse
  | PurchaseTransactionResponse;

export interface CreditUnitsRequest {
  practitionerWalletId: string;
  amount: number;
  comment: string;
  reference: string;
  organisationId: string;
}

export interface TransferUnitsRequest {
  fromWalletId: string;
  toWalletId: string;
  amount: number;
  comment?: string;
  organisationId: string;
  emailStrategy?: string;
}

export interface TransferUnitsResponse {
  sourceWallet: WalletResponse;
  targetWallet: WalletResponse;
}

export interface TeamLearnerResponse {
  fullName: string;
  id: string;
  knownAs: string;
  primaryEmail: string;
  latestProfile?: {
    createdAt: string;
    id: string;
    leadingColourEnergy: JungianColours;
    profileType?: ProfileType;
    colourScores?: {
      conscious: ColourScoreWithPreference;
    };
    evaluation?: {
      id: string;
    };
    organisation: Pick<OrganisationResponse, 'name' | 'id'>;
  };
  latestEvaluation: {
    id: string;
    submittedOn: string;
    organisation: Pick<OrganisationResponse, 'name' | 'id'>;
  };
  preferredDialect: Dialects;
}

export interface GetTeamLearnersResponse {
  learners: TeamLearnerResponse[];
  limitExceeded: boolean;
}

export type TeamType = 'SINGLE' | 'MULTIPLE';
export interface OrganisationContext {
  type: TeamType;
  organisations: OrganisationResponse[];
  distinctLearnerCount: number;
}

export type TeamVisibility = 'PRIVATE' | 'ORGANISATION';

export interface TeamResponse {
  id: string;
  name: string;
  learnerCount: number;
  visibility: TeamVisibility;
  organisationContext?: OrganisationContext;
  migrated: boolean;
  location: string | null;
}

export interface EventReminderResponse {
  reminderAt?: string;
}

export interface EvaluatorLink {
  id: string;
  link: string;
  name: string;
  totalCompletedEvaluators: number;
  organisation: OrganisationResponse;
  evaluatorModel: Evaluator;
  origin: {
    createdAt: string;
    practitioner: {
      id: string;
    };
  };
  dialect: Dialects;
}

export interface EvaluatorLinkResponse {
  evaluatorLinks: EvaluatorLink[];
}

export interface EvaluationChapters {
  name: Chapter;
}

export interface CompletedEvaluation {
  id: string;
  learner: {
    id: string;
    fullName: string;
    emailAddress: string;
  };
  profile: {
    id: string;
    chapters: ChaptersStatus[];
    createdAt: string;
  } | null;
  colourScores: {
    conscious: Record<ColourScoreColour, number> & {
      preferred: ColourScoreColour[];
    };
  } | null;
  referralCode: string;
  createdAt: string;
}

export interface FlattenedCompletedEvaluation {
  id: string;
  learnerId: string;
  fullName: string;
  emailAddress: string;
  referralCode: string;
  dateCompleted: string;
  latestPurchase: string | null;
  purchased: boolean;
  chapters: string[];
  colourScores:
    | (Record<ColourScoreColour, number> & {
        preferred: ColourScoreColour[];
      })
    | null;
}

export interface GetCompletedEvaluationResponse {
  evaluations: CompletedEvaluation[];
  limitExceeded?: boolean;
}

export interface EvaluationsSearchResponse {
  id: string;
  submittedOn: string;
  latestProfile: {
    id: string;
    createdAt: string;
    chapters: string[];
    profileType?: string;
  } | null;
  latestProfiles: Profile[] | [];
  learner: LearnerResponse;
}

export interface EvaluationsProfilesResponse {
  evaluations: EvaluationsSearchResponse[];
}

export type InviteeBulkResult =
  | { type: 'SUCCESS'; inviteeData: InviteeRequest }
  | { type: 'FAILURE'; inviteeData: InviteeRequest; error: RequestError };

export const allGroupTypes = ['CUSTOMER', 'PARTNER'] as const;
export type GroupType = (typeof allGroupTypes)[number];

export interface Group {
  id: string;
  name: string;
  type: GroupType;
}

export interface GroupResponse extends Group {
  organisations: OrganisationResponse[];
}

export interface GetGroupResponse {
  organisationGroups: GroupResponse[];
}

export type GetContributorsInvitees = {
  id: string;
  contributorInvitees: Contributor[];
  readyToPurchase?: boolean;
}[];

export interface GetContributorsResponse {
  invitees: GetContributorsInvitees;
}

export interface AddContributorRequest {
  learnerInviteeId: string;
  fullName: string;
  email: string;
}

export interface ContactFormRequest {
  practitionerId: string;
  subject: string;
  formBody: string;
}
