import { _delete, get, put, post } from '@insights-ltd/design-library/utils';
import {
  FilteredLearnerResponse,
  LearnerWithEvaluation,
  RequestErrorBody,
  TeamResponse,
} from 'api';
import { API_ROOT, NoResponse } from 'mocks/handlers/common';
import { LearnersHttpResponse } from 'test/types';
import { ProfilesResponse } from 'api/organisations/organisationApitypes';
import { EventsResponse } from 'api/practitioners/practitionerApiTypes';

export const learners = {
  get: (organisationId: string, learnerId: string) =>
    get<LearnerWithEvaluation | undefined>(
      `${API_ROOT}/organisations/${organisationId}/learners/${learnerId}`,
    ),
  getLearnerTeams: (learnerId: string) =>
    get<{ teams: TeamResponse[] }>(
      `${API_ROOT}/practitioners/me/learners/${learnerId}/teams`,
    ),
  getOrganisationLearners: (organisationId: string) =>
    get<FilteredLearnerResponse>(
      `${API_ROOT}/practitioners/me/organisations/${organisationId}/learners`,
    ),
  all: get<LearnersHttpResponse | undefined>(
    `${API_ROOT}/practitioners/me/learners`,
  ),
  update: (learnerId: string) =>
    put<undefined | RequestErrorBody>(
      `${API_ROOT}/practitioners/me/learners/${learnerId}`,
    ),
  delete: (learnerId: string) =>
    _delete<undefined>(`${API_ROOT}/practitioners/me/learners/${learnerId}`),
  search: post<FilteredLearnerResponse>(
    `${API_ROOT}/practitioners/me/learner-searches`,
  ),
  profiles: {
    all: (organisationId: string, learnerId: string) =>
      get<ProfilesResponse>(
        `${API_ROOT}/organisations/${organisationId}/learners/${learnerId}/profiles`,
      ),
    download: (learnerId: string, profileId: string) =>
      get<undefined>(
        `${API_ROOT}/practitioners/me/learners/${learnerId}/profiles/${profileId}`,
      ),
    email: (learnerId: string, profileId: string) =>
      post<NoResponse>(
        `${API_ROOT}/practitioners/me/learners/${learnerId}/profiles/${profileId}/email`,
      ),
    emailLink: (learnerId: string, profileId: string) =>
      post<undefined>(
        `${API_ROOT}/practitioners/me/learners/${learnerId}/profiles/${profileId}/email`,
      ),
  },
  events: {
    all: (learnerId: string) =>
      get<EventsResponse>(
        `${API_ROOT}/practitioners/me/learners/${learnerId}/events`,
      ),
  },
};
